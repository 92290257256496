import styled from 'styled-components';

const Component = styled.div``;

const Table = styled.div`
	background: white;
	border-radius: 0.5rem;
	overflow: auto;
	-ms-overflow-style: none;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
`;

const TableCell = styled.div`
	padding: 0.5rem 1rem;
	border-bottom: 1px solid var(--bs-light);
	border-right: 1px solid var(--bs-light);
`;

const TableRow = styled.div`
	display: grid;
	grid-template-columns: ${({ count }) => `repeat(${count}, minmax(20rem, 1fr))`};

	${TableCell}:last-of-type {
		border-right: 0;
	}

	&:last-of-type ${TableCell} {
		border-bottom: 0;
	}
`;

const TableHeader = styled(TableRow)`
	font-weight: bold;
`;

const TableBody = styled(TableRow)`
	font-size: 0.9rem;
	line-height: 1.25;
`;

const Verify = ({ importedCsv }) => {
	// Props
	const headers = Object.keys(importedCsv[0]);
	const fields = importedCsv.map(item => Object.values(item));
	const count = headers.length;

	return (
		<Component>
			<h5 className='mb-4'>Verify that the imported csv data below is correct</h5>
			<Table>
				<TableHeader count={count}>
					{headers.map((item, i) => (
						<TableCell key={item + i} dangerouslySetInnerHTML={{ __html: item }} />
					))}
				</TableHeader>
				{fields.map((item, i) => (
					<TableBody count={count} key={item + i}>
						{item.map((field, i) => (
							<TableCell key={field + i} dangerouslySetInnerHTML={{ __html: field }} />
						))}
					</TableBody>
				))}
			</Table>
		</Component>
	);
};

export default Verify;
