import { UserProvider } from './utils/UserContext';
import { MessageProvider } from './utils/MessageContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import App from './App';
import './styles/App.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<Router>
		<CookiesProvider>
			<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
				<HelmetProvider>
					<UserProvider>
						<MessageProvider>
							<App />
						</MessageProvider>
					</UserProvider>
				</HelmetProvider>
			</GoogleOAuthProvider>
		</CookiesProvider>
	</Router>
);
