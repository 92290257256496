import styled from 'styled-components';

import ProfileImage from 'components/shared/ProfileImage';

const Component = styled.div`
	padding: 1rem;
	border-radius: 0.5rem;
	text-align: center;
`;

const Name = styled.h6`
	margin: 1rem 0 0.5rem;
`;

const Title = styled.p`
	margin: 0;
`;

const Location = styled.p`
	margin: 0;
	color: var(--bs-secondary);
`;

const Email = styled.a`
	margin: 0;
	word-break: break-all;
`;

const Designer = ({ designer }) => {
	// Destructure props
	const { email, imageUrl, jobTitle, name, userLocation } = { ...designer };

	return (
		<Component>
			<ProfileImage src={imageUrl} alt={name} width={100} />
			<Name>{name}</Name>
			<Title>{jobTitle}</Title>
			<Location>{userLocation}</Location>
			<Email href={`mailto:${email}`}>{email}</Email>
		</Component>
	);
};

export default Designer;
