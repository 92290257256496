import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { useMessage } from 'utils/MessageContext';
import ObjectID from 'bson-objectid';

const Component = styled.div``;

const CheckRow = styled.div`
	padding: 0.5rem;
	border-bottom: 1px solid #ddd;

	&.unchecked label {
		text-decoration: line-through;
	}
`;

const Configure = ({ csvFields, setCsvFields }) => {
	// Data
	const defaultCheckboxes = [
		{
			name: 'Name',
			checked: true,
			readOnly: true,
			id: ObjectID().toHexString()
		},
		{
			name: 'Address',
			checked: true,
			id: ObjectID().toHexString()
		},
		{
			name: 'General Info',
			checked: true,
			id: ObjectID().toHexString()
		}
	];

	const fieldSet = csvFields.length > 0 ? csvFields : defaultCheckboxes;

	// State
	const [fields, setFields] = useState(fieldSet);
	const [fieldName, setFieldName] = useState('');

	// Hooks
	const { setMessage } = useMessage();

	// Handlers
	const handleToggle = (check, i) => {
		const { name, checked, id } = check;
		const newFields = [...fields];
		newFields[i] = { name, checked: !checked, id };
		setFields(newFields);
	};

	const handleChange = e => {
		const { value } = e.target;
		setFieldName(value);
	};

	const handlePreventSubmit = e => {
		if (e.key === 'Enter') {
			e.preventDefault();
			handleAddField();
		}
	};

	const handleAddField = () => {
		const exists = csvFields.find(field => field.name.toLowerCase() === fieldName.toLowerCase());
		if (!exists) {
			setFields(prev => [...prev, { name: fieldName, checked: true, id: ObjectID().toHexString() }]);
			setFieldName('');
		} else {
			setMessage({ variant: 'danger', text: `Cannot add field with duplicate field name "${fieldName}"` });
		}
	};

	// Effects
	useEffect(() => {
		setCsvFields(fields.filter(field => field.checked));
	}, [fields, setCsvFields]);

	return (
		<Component>
			<Row>
				<Col lg={6}>
					<p>Select the fields you want to include in the csv template to send to the customer.</p>
					<Form.Group>
						{fields.map((check, i) => (
							<CheckRow key={check.id} className={`${!check.checked && 'unchecked'}`}>
								<Form.Check type='checkbox' id={check.id} label={check.name} onChange={() => handleToggle(check, i)} checked={check.checked} disabled={check.readOnly} />
							</CheckRow>
						))}
					</Form.Group>
				</Col>
				<Col lg={6}>
					<h5 className='mb-3'>Add new field</h5>
					<Form.Group>
						<Form.Label>Field name</Form.Label>
						<Form.Control type='text' className='shadow-sm rounded' value={fieldName} onChange={e => handleChange(e)} onKeyDown={handlePreventSubmit} />
					</Form.Group>
					<Button className='mt-3' onClick={handleAddField}>
						Add field <i className='fas fa-plus'></i>
					</Button>
				</Col>
			</Row>
		</Component>
	);
};

export default Configure;
