import { Section, Panel, Name, Value, Grid } from 'components/Briefs/Shared/Components';
import { tidyDateWords } from 'utils/Tidy';
import styled from 'styled-components';

const CustomGrid = styled(Grid)`
	grid-template-columns: repeat(5, 1fr);

	@media (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const ProjectDetails = ({ timeline }) => {
	return (
		<Section>
			<h3>Timeline</h3>
			<CustomGrid>
				{timeline.map(event => (
					<Panel key={event.name}>
						<Name>{event.name}</Name>
						<Value>{tidyDateWords(event.date)}</Value>
					</Panel>
				))}
			</CustomGrid>
		</Section>
	);
};

export default ProjectDetails;
