import { Section, Panel, Name, Value, Grid } from 'components/Briefs/Shared/Components';
import styled from 'styled-components';

const CustomGrid = styled(Grid)`
	grid-template-columns: repeat(2, 1fr);

	@media (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const ProjectDetails = ({ creative_brief }) => {
	// Destructure props
	const { domain_name, domain_registrar, domain_ownership, is_email_set_up_on_this_domain, registrar_username, registrar_password } = { ...creative_brief };
	const exists = domain_name || domain_registrar || domain_ownership || is_email_set_up_on_this_domain || registrar_username || registrar_password ? true : false;

	return (
		exists && (
			<Section>
				<h3>Domain Info</h3>
				<CustomGrid>
					{domain_name && (
						<Panel>
							<Name>Domain Name</Name>
							<Value>{domain_name}</Value>
						</Panel>
					)}
					{domain_registrar && (
						<Panel>
							<Name>Domain Registrar</Name>
							<Value>{domain_registrar}</Value>
						</Panel>
					)}
					{domain_ownership && (
						<Panel>
							<Name>Domain Ownership</Name>
							<Value>{domain_ownership}</Value>
						</Panel>
					)}
					{is_email_set_up_on_this_domain && (
						<Panel>
							<Name>Is email set up on this domain?</Name>
							<Value>{is_email_set_up_on_this_domain}</Value>
						</Panel>
					)}
					{registrar_username && (
						<Panel>
							<Name>Registrar Username</Name>
							<Value>{registrar_username}</Value>
						</Panel>
					)}
					{registrar_password && (
						<Panel>
							<Name>Registrar Password</Name>
							<Value>{registrar_password}</Value>
						</Panel>
					)}
				</CustomGrid>
			</Section>
		)
	);
};

export default ProjectDetails;
