import { useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

import { tidyLocalDateTime } from 'utils/Tidy';
import dayjs from 'dayjs';
import Linkify from 'react-linkify';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import { mutate } from 'swr';
import { useNavigate } from 'react-router-dom';
import { fetcher } from 'utils/Fetch';

const Highlight = styled.span`
	color: var(--Gold);
`;

const Anchor = styled.a`
	text-decoration: none !important;
	border-bottom: 1px dotted;

	&:hover,
	&:focus-visible {
		border-bottom: 1px solid;
	}
`;

const WhatWeNeed = ({ plan, designer }) => {
	// Props
	const { stage, assets, assets_url, kick_off_url, revisions_url, events } = { ...plan };

	// State
	const [openCalendlyModal, setOpenCalendlyModal] = useState(false);

	// Hooks
	const navigate = useNavigate();
	useCalendlyEventListener({ onEventScheduled: e => handleEventSchedule(e) });

	// Events
	const kickOffCall = events && events.length > 0 && events.find(event => event.name === 'Kick Off Call');
	const revisionsCall = events && events.length > 0 && events.find(event => event.name === 'Revisions Call' && dayjs().isBefore(dayjs(event.date)));

	// Linkify decorator
	const componentDecorator = (href, text, key) => (
		<Anchor href={href} key={key} target='_blank' rel='noopener noreferrer'>
			{text}
		</Anchor>
	);

	// Handlers
	const handleEventSchedule = async e => {
		// Destructure event
		const {
			data: { payload }
		} = e;

		// Determine event
		let event_name;
		switch (stage) {
			case 'Welcome':
				event_name = 'Kick Off Call';
				break;
			case 'Review':
				event_name = 'Revisions Call';
				break;
			default:
				break;
		}

		// Fetch event details from Calendly API
		try {
			const {
				resource: { start_time }
			} = await fetcher('/api/v1/events/details', { method: 'POST', body: JSON.stringify({ uri: payload.event.uri, id: designer._id }) });

			// Submit
			const body = { events: [{ name: event_name, url: payload.event.uri, date: start_time }] };
			await fetcher(`/api/v1/plans/${plan._id}`, { method: 'PUT', body: JSON.stringify(body) });

			// Refetch
			await mutate(`/api/v1/plans/${plan._id}`);
			setTimeout(() => setOpenCalendlyModal(false), 1000);
		} catch (error) {
			navigate(`/error`);
		}
	};

	// Conditional render
	switch (stage) {
		case 'Welcome':
			return kick_off_url ? (
				<>
					{!kickOffCall ? (
						<div className='mb-3'>
							<PopupModal url={kick_off_url} onModalClose={() => setOpenCalendlyModal(false)} open={openCalendlyModal} rootElement={document.getElementById('root')} />
							<Button onClick={() => setOpenCalendlyModal(true)} variant='primary'>
								Schedule Kickoff Call Now <i className='fas fa-calendar-alt'></i>
							</Button>
						</div>
					) : assets.length > 0 ? (
						<div className='mb-3'>
							<ul>
								{assets.map(asset => (
									<li key={asset._id}>
										<Linkify componentDecorator={componentDecorator}>{asset.name}</Linkify> {asset.complete && <i className='fas fa-check text-success ms-1'></i>}
									</li>
								))}
							</ul>
							{assets_url && (
								<Button className='mb-3' variant='primary' href={assets_url} target='_blank' rel='noopener noreferer'>
									Upload Now <i className='fas fa-upload'></i>
								</Button>
							)}
							<p>
								I look forward to working with you on this project, see you on <Highlight>{tidyLocalDateTime(kickOffCall.date)}</Highlight> 💃
							</p>
						</div>
					) : (
						<p>No assets listed.</p>
					)}
				</>
			) : (
				<p>I will generate a link for you to schedule a kickoff call with me.</p>
			);
		case 'Collect':
		case 'Build':
			return assets.length > 0 ? (
				<div className='mb-3'>
					<ul>
						{assets.map(asset => (
							<li key={asset._id} style={{ textDecoration: asset.complete && 'line-through' }}>
								<Linkify componentDecorator={componentDecorator}>{asset.name}</Linkify> {asset.complete && <i className='fas fa-check text-success ms-1'></i>}
							</li>
						))}
					</ul>
					{assets_url && (
						<Button variant='primary' href={assets_url} target='_blank' rel='noopener noreferer'>
							Upload Now <i className='fas fa-upload'></i>
						</Button>
					)}
				</div>
			) : (
				<p>No assets listed.</p>
			);
		case 'Review':
			return (
				<div className='mb-3'>
					<PopupModal url={revisions_url} onModalClose={() => setOpenCalendlyModal(false)} open={openCalendlyModal} rootElement={document.getElementById('root')} />
					<ul>
						<li style={{ textDecoration: revisionsCall && 'line-through' }}>Schedule a website revisions call {revisionsCall && <i className='fas fa-check text-success ms-1'></i>}</li>
					</ul>
					{revisions_url && !revisionsCall && (
						<Button onClick={() => setOpenCalendlyModal(true)} variant='primary'>
							Schedule Now <i className='fas fa-calendar-alt'></i>
						</Button>
					)}
					<div className='mt-3'>
						<h6>Assets</h6>
						<ul>
							{assets.map(asset => (
								<li key={asset._id} style={{ textDecoration: asset.complete && 'line-through' }}>
									<Linkify componentDecorator={componentDecorator}>{asset.name}</Linkify> {asset.complete && <i className='fas fa-check text-success ms-1'></i>}
								</li>
							))}
						</ul>
						{assets_url && (
							<Button variant='primary' href={assets_url} target='_blank' rel='noopener noreferer'>
								Upload Now <i className='fas fa-upload'></i>
							</Button>
						)}
					</div>
				</div>
			);
		case 'Live':
			return <p>Nothing, your site is live!</p>;
		default:
			return null;
	}
};

export default WhatWeNeed;
