import { Subsection, Panel, Name, Value, Grid } from 'components/Briefs/Shared/Components';
import { tidyParagraphs } from 'utils/Tidy';

const GlobalQuestions = ({ creative_brief }) => {
	// Destructure props
	const { how_long_have_you_been_in_business, what_are_your_values_or_mission_statement, how_do_you_currently_attract_new_investors, what_do_you_like_or_dislike_about_your_current_website, give_me_an_example_of_a_website_you_really_like } = { ...creative_brief };

	const exists = how_long_have_you_been_in_business || what_are_your_values_or_mission_statement || how_do_you_currently_attract_new_investors || what_do_you_like_or_dislike_about_your_current_website || give_me_an_example_of_a_website_you_really_like ? true : false;

	return (
		exists && (
			<Subsection>
				<h5 className='text-primary'>Global Questions</h5>
				<Grid>
					{how_long_have_you_been_in_business && (
						<Panel>
							<Name>How long have you been in business? How did you get started?</Name>
							<Value>{tidyParagraphs(how_long_have_you_been_in_business)}</Value>
						</Panel>
					)}
					{what_are_your_values_or_mission_statement && (
						<Panel>
							<Name>What are your values or mission statement? What sets you apart from other Investment Managers?</Name>
							<Value>{tidyParagraphs(what_are_your_values_or_mission_statement)}</Value>
						</Panel>
					)}
					{how_do_you_currently_attract_new_investors && (
						<Panel>
							<Name>How do you currently attract new investors? Do you have a pitch deck you show to prospective clients? Would you mind walking me through the highlights? What do you want to bring over to your new website?</Name>
							<Value>{tidyParagraphs(how_do_you_currently_attract_new_investors)}</Value>
						</Panel>
					)}
					{what_do_you_like_or_dislike_about_your_current_website && (
						<Panel>
							<Name>What do you like (or dislike) about your current website? What content do you want me to pull over from your existing site? What do you want to leave off?</Name>
							<Value>{tidyParagraphs(what_do_you_like_or_dislike_about_your_current_website)}</Value>
						</Panel>
					)}
					{give_me_an_example_of_a_website_you_really_like && (
						<Panel>
							<Name>Give me an example of a website you really like. What do you like about it?</Name>
							<Value>{tidyParagraphs(give_me_an_example_of_a_website_you_really_like)}</Value>
						</Panel>
					)}
				</Grid>
			</Subsection>
		)
	);
};

export default GlobalQuestions;
