import { Section, Panel, Name, Value, Grid } from 'components/Briefs/Shared/Components';
import { tidyParagraphs } from 'utils/Tidy';

const ProjectDetails = ({ creative_brief }) => {
	// Destructure props
	const { contact_details } = { ...creative_brief };
	const exists = contact_details && contact_details !== 'Company Name: \nPhone: \nFax: \nEmail: \nAddress: \nOffice Hours: \nSocial Media Accounts:' ? true : false;

	return (
		exists && (
			<Section>
				<h3>Company Info</h3>
				<Grid>
					{contact_details && (
						<Panel>
							<Name>Contact Details</Name>
							<Value>{tidyParagraphs(contact_details)}</Value>
						</Panel>
					)}
				</Grid>
			</Section>
		)
	);
};

export default ProjectDetails;
