import { useState, useEffect } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { usePapaParse, useCSVDownloader } from 'react-papaparse';
import { addUnderscores } from 'utils/Tidy';

const Component = styled.div``;

const FieldRow = styled.div`
	background: var(--lightest);
	padding: 0.5rem;
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	border-bottom: 1px solid #ddd;

	&:not(:first-of-type) {
		cursor: grab;
	}

	&.dragging {
		background: white;
		box-shadow: 0 2px 5px #0003;
		border-radius: 0.5rem;
	}
`;

const Generate = ({ csvFields, setCsvFields }) => {
	// Hooks
	const { jsonToCSV } = usePapaParse();
	const { CSVDownloader } = useCSVDownloader();

	// State
	const [csvOutput, setCsvOutput] = useState();

	// Handlers
	const handleDragEnd = result => {
		if (!result.destination || result.destination.index === 0) {
			return;
		}
		const newFields = [...csvFields];
		const [removed] = newFields.splice(result.source.index, 1);
		newFields.splice(result.destination.index, 0, removed);
		setCsvFields(newFields);
	};

	// Effects
	useEffect(() => {
		const fields = csvFields.map(field => addUnderscores(field.name));
		setCsvOutput(jsonToCSV({ fields }));
	}, [csvFields, jsonToCSV]);

	return (
		<Component>
			<Row>
				<Col lg={6}>
					<p>
						The csv template will be generated with the following <strong>{csvFields.length}</strong> columns:
					</p>
					<DragDropContext onDragEnd={handleDragEnd}>
						<Droppable droppableId='droppable'>
							{provided => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{csvFields.map((field, i) => (
										<Draggable draggableId={field.name + i} key={field.name + i} index={i} isDragDisabled={field.name === 'Name'}>
											{(provided, snapshot) => (
												<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<FieldRow key={field.name + i} className={snapshot.isDragging && 'dragging'}>
														<div>{field.name}</div>
														{field.name !== 'Name' && <i className='fas fa-grip-lines text-muted'></i>}
													</FieldRow>
												</div>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</Col>
				<Col lg={6}>
					<h5>Instructions</h5>
					<ol>
						<li>Drag & drop fields to order them appropriately</li>
						<li>Download the csv template below</li>
						<li>Send to the customer to complete with property data</li>
					</ol>
					<Button as={CSVDownloader} variant='dark' filename='AppFolio InvestmentManagement Websites - Collection Template' data={csvOutput}>
						Download csv template <i className='fas fa-download'></i>
					</Button>
				</Col>
			</Row>
		</Component>
	);
};

export default Generate;
