import styled from 'styled-components';

import { Helmet } from 'react-helmet-async';

const Component = styled.div`
	color: var(--bs-danger);
	text-align: center;
	padding: 3rem 1rem;
`;

const Heading = styled.h1`
	font-size: 5rem;
`;

const Text = styled.p`
	margin: 0;
`;

const Error = () => {
	return (
		<Component>
			<Helmet>
				<title>Error | AppFolio Investment Management | Project Plan Tool</title>
			</Helmet>
			<Heading>
				<i className='fas fa-exclamation-circle'></i> Error
			</Heading>
			<Text>Sorry, looks like there was an problem.</Text>
		</Component>
	);
};

export default Error;
