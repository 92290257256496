import { Field } from 'formik';
import { Row, Col, Form } from 'react-bootstrap';

const DesignOptions = ({ values }) => {
	// Destructure props
	const { website_type } = { ...values };

	const templateMap = {
		undefined: [],
		Fixed: ['', 'Obsidian', 'Sapphire', 'Limestone'],
		Flexible: ['', 'Basalt', 'Quartz', 'Slate', 'Granite', 'Topaz']
	};
	return (
		<div className='my-3'>
			<Row>
				<Col lg={6}>
					<div className='mb-3'>
						<Form.Label>Website Type</Form.Label>
						<Field as='select' className='form-select' name='website_type'>
							<option value='Fixed'>Fixed</option>
							<option value='Flexible'>Flexible</option>
						</Field>
					</div>
				</Col>
				<Col lg={6}>
					<div className='mb-3'>
						<Form.Label>Website Template</Form.Label>
						<Field as='select' className='form-select' name='website_template'>
							{templateMap[website_type]?.map(template => (
								<option key={template} value={template}>
									{template}
								</option>
							))}
						</Field>
					</div>
				</Col>
			</Row>
			<div className='mb-3'>
				<Form.Label>Why did the customer choose this design?</Form.Label>
				<Field as='textarea' className='form-control' name='why_did_the_customer_choose_this_design' rows={5} />
			</div>
		</div>
	);
};

export default DesignOptions;
