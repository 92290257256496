import { Section } from 'components/Briefs/Shared/Components';

// Sections
import GeneralContent from './WebsiteContent/GeneralContent';
import GlobalQuestions from './WebsiteContent/GlobalQuestions';
import GranularQuestions from './WebsiteContent/GranularQuestions';

const WebsiteContent = ({ creative_brief }) => {
	// Destructure props
	const { project_notes, website_goals, who_are_we_marketing_the_website_to, what_kind_of_deal_structures_do_you_manage, how_do_you_normally_raise_funds_attract_investors, how_do_you_showcase_your_past_projects_what_is_important_to_showcase, how_long_have_you_been_in_business, how_do_you_currently_attract_new_investors, what_do_you_like_or_dislike_about_your_current_website, give_me_an_example_of_a_website_you_really_like, what_type_of_properties_are_you_typically_looking_to_deal_with, what_investment_strategies_do_you_employ, would_you_like_to_highlight_any_performance_statistics_on_your_website, do_you_want_to_highlight_your_portfolio_past_projects } = { ...creative_brief };

	const exists = project_notes || website_goals || who_are_we_marketing_the_website_to?.length > 0 || what_kind_of_deal_structures_do_you_manage?.length > 0 || how_do_you_normally_raise_funds_attract_investors || how_do_you_showcase_your_past_projects_what_is_important_to_showcase || how_long_have_you_been_in_business || how_do_you_currently_attract_new_investors || what_do_you_like_or_dislike_about_your_current_website || give_me_an_example_of_a_website_you_really_like || what_type_of_properties_are_you_typically_looking_to_deal_with || what_investment_strategies_do_you_employ || would_you_like_to_highlight_any_performance_statistics_on_your_website || do_you_want_to_highlight_your_portfolio_past_projects ? true : false;

	return (
		exists && (
			<Section>
				<h3>Website Content</h3>
				<GeneralContent creative_brief={creative_brief} />
				<GlobalQuestions creative_brief={creative_brief} />
				<GranularQuestions creative_brief={creative_brief} />
			</Section>
		)
	);
};

export default WebsiteContent;
