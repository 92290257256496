import { Section, Panel, Name, Value, Grid } from 'components/Briefs/Shared/Components';
import { tidyParagraphs } from 'utils/Tidy';
import styled from 'styled-components';

const CustomGrid = styled(Grid)`
	grid-template-columns: repeat(2, 1fr);

	@media (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const DesignAesthetics = ({ creative_brief }) => {
	// Destructure props
	const { logo_status, logo_notes, color_scheme, photos } = { ...creative_brief };

	const exists = logo_status || logo_notes || color_scheme || photos ? true : false;

	return (
		exists && (
			<Section>
				<h3>Design Aesthetics</h3>
				<CustomGrid>
					{logo_status && (
						<Panel>
							<Name>Logo Status</Name>
							<Value>{logo_status}</Value>
						</Panel>
					)}
					{logo_notes && (
						<Panel>
							<Name>Logo Notes</Name>
							<Value>{tidyParagraphs(logo_notes)}</Value>
						</Panel>
					)}
					{color_scheme && (
						<Panel>
							<Name>Color Scheme</Name>
							<Value>{color_scheme}</Value>
						</Panel>
					)}
					{photos && (
						<Panel>
							<Name>Photos</Name>
							<Value>{tidyParagraphs(photos)}</Value>
						</Panel>
					)}
				</CustomGrid>
			</Section>
		)
	);
};

export default DesignAesthetics;
