import styled from 'styled-components';
import logo from 'img/app/appfolio_text_logo.svg';
import { useLogin } from 'utils/UserContext';
import { Link } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import ProfileImage from 'components/shared/ProfileImage';

const Component = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: white;
	padding: 1rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	grid-area: header;
`;

const HeaderLeft = styled.div`
	display: flex;
	flex-direction: column;

	h2 {
		text-transform: uppercase;
		font-size: 1rem;
		margin: 0.25rem 0 0 0;
		letter-spacing: 0.25rem;
		color: var(--Blue);
	}

	a {
		text-decoration: none;
	}
`;

const UserInfo = styled.div`
	display: grid;
	grid-template-columns: repeat(2, auto);
	align-items: center;
	gap: 1rem;

	@media (max-width: 576px) {
		grid-template-columns: 1fr;
		gap: 0.5rem;
	}
`;

const UserName = styled.div`
	font-size: 0.75rem;
	line-height: 1.25;
	text-align: right;
	display: grid;
	gap: 0.25rem;

	@media (max-width: 576px) {
		order: 1;
	}
`;

const SignOutButton = styled.button`
	all: unset;
	color: var(-bs-primary) !important;

	&:hover,
	&:focus-visible {
		text-decoration: underline;
	}
`;

const Header = () => {
	// Hooks
	const { user, setUser } = useLogin();

	// On successful logout
	const handleLogout = async () => {
		googleLogout();
		await fetch('/auth/signout');
		setUser(null);
	};

	return (
		<Component>
			<HeaderLeft>
				<Link to='/' className='text-decoration-none'>
					<img src={logo} alt='AppFolio Investment Management' width='200' />
					<h2>Project Plan Tool</h2>
				</Link>
			</HeaderLeft>
			<UserInfo>
				<UserName>
					<div className='mobile-hide'>
						<div>Signed in as:</div>
						<div>
							<strong>{user.email}</strong>
						</div>
					</div>
					<SignOutButton onClick={() => handleLogout()}>Sign out</SignOutButton>
				</UserName>
				<ProfileImage src={user.imageUrl} alt={user.name} width={40} />
			</UserInfo>
		</Component>
	);
};

export default Header;
