import styled from 'styled-components';

const Component = styled.div`
	margin: 0 0 2rem;

	@media (max-width: 991px) {
		margin: 0 0 1rem;
	}
`;

const PageHeader = ({ heading, subheading }) => {
	return (
		<Component>
			<h1>{heading}</h1>
			<h6 className='text-muted fw-normal'>{subheading}</h6>
		</Component>
	);
};

export default PageHeader;
