import styled from 'styled-components';

import kiwi from 'img/app/kiwi.svg';

// Styled components
const Component = styled.footer`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: var(--bs-primary);
	color: var(--bs-light);
	margin-top: auto;
	padding: 1rem;
	font-size: 0.75rem;

	p {
		margin: 0;
	}

	a {
		color: inherit;
	}

	.divider {
		margin: 0 0.25rem;
	}

	@media (max-width: 767px) {
		flex-direction: column;
		align-items: center;
		gap: 0.5rem;
	}
`;

const FooterLeft = styled.div``;

const FooterRight = styled.div`
	text-align: right;
`;

const Footer = () => {
	return (
		<Component>
			<FooterLeft>
				<img src={kiwi} alt='Kiwi' width='20' className='me-2' />
				<a href='https://forms.gle/5J3RHgyfBjydN4927' target='_blank' rel='noopener noreferrer'>
					Have a suggestion? Submit feedback here.
				</a>
			</FooterLeft>
			<FooterRight>
				<p>© {new Date().getFullYear()} AppFolio, All rights reserved.</p>
				<a href='https://www.appfolio.com/terms/websites' target='_blank' rel='noopener noreferrer'>
					Terms of Service
				</a>
				<span className='divider'>|</span>
				<a href='https://www.appfolio.com/privacy' target='_blank' rel='noopener noreferrer'>
					Privacy Policy
				</a>
			</FooterRight>
		</Component>
	);
};

export default Footer;
