import { Subsection, Panel, Name, Value, Grid } from 'components/Briefs/Shared/Components';
import { tidyParagraphs } from 'utils/Tidy';

const GranularQuestions = ({ creative_brief }) => {
	// Destructure props
	const { what_type_of_properties_are_you_typically_looking_to_deal_with, what_investment_strategies_do_you_employ, would_you_like_to_include_a_section_about_your_team_members, would_you_like_to_highlight_any_performance_statisticson_your_website, do_you_want_to_highlight_your_portfolio_past_projects } = { ...creative_brief };

	const exists = what_type_of_properties_are_you_typically_looking_to_deal_with || what_investment_strategies_do_you_employ || would_you_like_to_include_a_section_about_your_team_members || would_you_like_to_highlight_any_performance_statisticson_your_website || do_you_want_to_highlight_your_portfolio_past_projects ? true : false;

	return (
		exists && (
			<Subsection>
				<h5 className='text-primary'>Granular Questions</h5>
				<Grid>
					{what_type_of_properties_are_you_typically_looking_to_deal_with && (
						<Panel>
							<Name>What types of properties are you typically looking to deal with?</Name>
							<Value>{tidyParagraphs(what_type_of_properties_are_you_typically_looking_to_deal_with)}</Value>
						</Panel>
					)}
					{what_investment_strategies_do_you_employ && (
						<Panel>
							<Name>What investment strategies do you employ? Are there any elements of your strategy that you want to highlight on the website?</Name>
							<Value>{tidyParagraphs(what_investment_strategies_do_you_employ)}</Value>
						</Panel>
					)}
					{would_you_like_to_include_a_section_about_your_team_members && (
						<Panel>
							<Name>Would you like to include a section about your team members? Please provide bios and headshots.</Name>
							<Value>{would_you_like_to_include_a_section_about_your_team_members}</Value>
						</Panel>
					)}
					{would_you_like_to_highlight_any_performance_statisticson_your_website && (
						<Panel>
							<Name>Would you like to highlight any performance statistics on your website?</Name>
							<Value>{tidyParagraphs(would_you_like_to_highlight_any_performance_statisticson_your_website)}</Value>
						</Panel>
					)}
					{do_you_want_to_highlight_your_portfolio_past_projects && (
						<Panel>
							<Name>Do you want to highlight your portfolio/past projects? What information would you like to be included and how many would you like to showcase?</Name>
							<Value>{tidyParagraphs(do_you_want_to_highlight_your_portfolio_past_projects)}</Value>
						</Panel>
					)}
				</Grid>
			</Subsection>
		)
	);
};

export default GranularQuestions;
