import { Section, Panel, Name, Value, Grid } from 'components/Briefs/Shared/Components';
import { tidyUrl } from 'utils/Tidy';
import styled from 'styled-components';

const CustomGrid = styled(Grid)`
	grid-template-columns: repeat(2, 1fr);

	@media (max-width: 767px) {
		grid-template-columns: 100%;
	}
`;

const ProjectDetails = ({ creative_brief }) => {
	// Destructure props
	const { appfolio_onboarding_contact, company_name, existing_website_url, project_type, final_approver, billing_date_agreement } = { ...creative_brief };
	const exists = appfolio_onboarding_contact || company_name || existing_website_url || project_type || final_approver || billing_date_agreement ? true : false;

	return (
		exists && (
			<Section>
				<h3>Project Details</h3>
				<CustomGrid>
					{appfolio_onboarding_contact && (
						<Panel>
							<Name>AppFolio Onboarding Contact</Name>
							<Value>{appfolio_onboarding_contact}</Value>
						</Panel>
					)}
					{company_name && (
						<Panel>
							<Name>Company Name</Name>
							<Value>{company_name}</Value>
						</Panel>
					)}
					{existing_website_url && (
						<Panel>
							<Name>Existing Website URL</Name>
							<Value>{tidyUrl(existing_website_url)}</Value>
						</Panel>
					)}
					{project_type && (
						<Panel>
							<Name>Project Type</Name>
							<Value>{tidyUrl(project_type)}</Value>
						</Panel>
					)}
					{final_approver && (
						<Panel>
							<Name>Final Approver</Name>
							<Value>{final_approver}</Value>
						</Panel>
					)}
					{billing_date_agreement?.length > 0 && (
						<Panel>
							<Name>Billing Date Agreement</Name>
							<Value>{billing_date_agreement[0]}</Value>
						</Panel>
					)}
				</CustomGrid>
			</Section>
		)
	);
};

export default ProjectDetails;
