import { useState, useEffect, useCallback } from 'react';
import PageHeader from 'components/shared/PageHeader';
import styled from 'styled-components';
import { useLogin } from 'utils/UserContext';

import { tidyUrl } from 'utils/Tidy';
import { Formik, Form, Field } from 'formik';
import useSWR, { mutate } from 'swr';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { useMessage } from 'utils/MessageContext';
import { Link } from 'react-router-dom';
import { fetcher } from 'utils/Fetch';
import ProfileImage from 'components/shared/ProfileImage';

const Panel = styled.div`
	background: white;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	padding: 1rem 2rem;
	text-align: center;
	max-width: 30rem;
`;

const Email = styled.h6`
	font-weight: normal;
	color: var(--Gold);
	margin: 1rem 0;
`;

const SmallText = styled.p`
	display: block;
	max-width: 17rem;
	margin: 0 auto;
	color: var(--bs-secondary);
	font-size: 0.75rem;
	line-height: 1.5;
`;

const Divider = styled.div`
	height: 2px;
	background: var(--bs-light);
	margin: 1rem 0;
`;

const Heading = styled.h6`
	color: var(--bs-primary);
`;

const SingleField = styled.div``;

const Name = styled.div`
	color: var(--bs-secondary);
	margin: 0.75rem 0 0;
`;

const FieldWrapper = styled.div`
	display: flex;
`;

const CustomField = styled(Field)`
	text-align: center;
	border: 0;
	outline: 0;
	border-bottom: 1px dotted var(--bs-light);
	width: 100%;
`;

const Value = styled.div`
	opacity: 0.75;
	font-weight: bold;
	transition: 0.2s;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}
`;

const CustomButton = styled.button`
	all: unset;
	padding: 0 0.25rem;
	opacity: 0.75;
	transition: 0.2s

	&:hover {
		opacity: 1;
	}
`;

const Submit = styled(CustomButton)`
	color: var(--bs-success);
`;

const Cancel = styled(CustomButton)`
	color: var(--bs-danger);
`;

const Loading = styled.div`
	color: var(--bs-primary);
	animation: spin 1s infinite;
	display: grid;
	place-items: center;
	font-size: 1.25rem;

	@keyframes spin {
		to {
			transform: rotate(360deg);
		}
	}
`;

const Mask = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;

	div {
		overflow: hidden;
		filter: blur(3px);
	}
`;

const CustomLink = styled(Link)`
	color: white !important;
	font-weight: bold;
	text-decoration: underline;
`;

const Designer = () => {
	// State
	const [editField, setEditField] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [tokenError, setTokenError] = useState(true);

	// Hooks
	const { user } = useLogin();
	const { _id } = { ...user };
	const { data: userData } = useSWR(`/api/v1/users/${_id}`);
	const { setMessage } = useMessage();

	// Destructuring
	const { imageUrl, email, name, jobTitle, userLocation, kick_off_url, revisions_url, access_token } = { ...userData };

	// Fields
	const designerFields = [
		{
			name: 'Name',
			value: 'name'
		},
		{
			name: 'Role',
			value: 'jobTitle'
		},
		{
			name: 'Location',
			value: 'userLocation'
		}
	];

	const schedulingFields = [
		{
			name: 'Kick-Off Call URL',
			value: 'kick_off_url'
		},
		{
			name: 'Revisions Call URL',
			value: 'revisions_url'
		},
		{
			name: 'Calendly Access Token',
			value: 'access_token'
		}
	];

	// Formik
	const initialValues = { imageUrl, email, name, jobTitle, userLocation, kick_off_url, revisions_url, access_token };

	// Handlers
	const handleTokenValidation = useCallback(
		async token => {
			try {
				await fetcher('https://api.calendly.com/users/me', { headers: { Authorization: `Bearer ${token}` } });
				setTokenError(false);
				setMessage(null);
			} catch (error) {
				setTokenError(true);
				setMessage({
					variant: 'danger',
					text: (
						<>
							Before proceeding, please add a Calendly access token to your account <CustomLink to={'/edit/designer'}>here.</CustomLink>
						</>
					),
					persist: true,
					global: true
				});
			}
		},
		[setMessage]
	);

	const handleSave = async values => {
		try {
			setIsLoading(true);
			if (editField === 'access_token') {
				await handleTokenValidation(values.access_token);
			}
			await fetcher(`/api/v1/users/${_id}`, { method: 'PUT', body: JSON.stringify(values) });
			await mutate(`/api/v1/users/${_id}`);
		} finally {
			setEditField('');
			setIsLoading(false);
		}
	};

	// Effects
	useEffect(() => {
		if (access_token) {
			handleTokenValidation(access_token);
		}
	}, [access_token, handleTokenValidation]);

	return (
		<div>
			<PageHeader heading='Edit Designer Info' subheading="Edit the information displayed on my client's project plans." />
			{userData ? (
				<Panel>
					<ProfileImage src={imageUrl} alt={name} width={100} />
					<Email>{email}</Email>
					<SmallText>
						<a className='text-muted text-decoration-none' href='https://myaccount.google.com' target='_blank' rel='noopener noreferrer'>
							Edit your profile picture in your Google account settings. <i className='fas fa-external-link-alt ms-1'></i>
						</a>
					</SmallText>
					<Divider />
					<Formik initialValues={initialValues} onSubmit={values => handleSave(values)}>
						{({ values, handleReset }) => (
							<Form>
								<Heading>Designer Info</Heading>
								{designerFields.map(field => (
									<SingleField key={field.name}>
										<Name>{field.name}</Name>
										{editField === field.value ? (
											<FieldWrapper>
												<Field name={field.value} as={CustomField}></Field>
												{!isLoading ? (
													<>
														<Submit type='submit'>
															<i className='fas fa-check'></i>
														</Submit>
														<Cancel
															onClick={() => {
																handleReset();
																setEditField('');
															}}>
															<i className='fas fa-times'></i>
														</Cancel>
													</>
												) : (
													<Loading>
														<i className='fas fa-spinner'></i>
													</Loading>
												)}
											</FieldWrapper>
										) : (
											<Value onClick={() => setEditField(field.value)}>{values[field.value]}</Value>
										)}
									</SingleField>
								))}
								<Divider />
								<Heading>Scheduling</Heading>
								{schedulingFields.map(field => (
									<SingleField key={field.name}>
										<Name>{field.name}</Name>
										{editField === field.value ? (
											<FieldWrapper>
												<Field name={field.value} as={CustomField}></Field>
												{!isLoading ? (
													<>
														<Submit type='submit'>
															<i className='fas fa-check'></i>
														</Submit>
														<Cancel
															onClick={() => {
																handleReset();
																setEditField('');
															}}>
															<i className='fas fa-times'></i>
														</Cancel>
													</>
												) : (
													<Loading>
														<i className='fas fa-spinner'></i>
													</Loading>
												)}
											</FieldWrapper>
										) : field.value === 'access_token' ? (
											<Value onClick={() => setEditField(field.value)}>
												{!tokenError ? (
													<Mask>
														<i className='fas fa-check-circle text-success me-1'></i> <div>{values[field.value]}</div>
													</Mask>
												) : (
													<>
														<i className='fas fa-times-circle text-danger me-1'></i> Not a valid token
													</>
												)}
											</Value>
										) : (
											<Value onClick={() => setEditField(field.value)}>{values[field.value] ? tidyUrl(values[field.value]) : <i className='fas fa-times-circle text-danger'></i>}</Value>
										)}
									</SingleField>
								))}
								<SmallText className='mt-2'>
									<i className='fas fa-info-circle me-1'></i>
									Generate a new Calendly access token{' '}
									<a href='https://calendly.com/integrations/api_webhooks' target='_blank' rel='noopener noreferrer'>
										here
									</a>{' '}
									by selecting <strong>Generate New Token</strong>.
								</SmallText>
							</Form>
						)}
					</Formik>
				</Panel>
			) : (
				<LoadingOverlay />
			)}
		</div>
	);
};

export default Designer;
