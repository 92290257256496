import { useEffect } from 'react';
import ProtectedRoute from './components/layout/ProtectedRoute';
import { useMessage } from './utils/MessageContext';
import { Routes, Route, useLocation } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { Helmet } from 'react-helmet-async';
import { fetcher } from './utils/Fetch';
import ReactGA from 'react-ga4';

// Components
import View from './components/Plans/Plan/View';
import Content from './components/Briefs/View+Content/Content';
import ViewBrief from './components/Briefs/View+Content/View';
import Login from './components/pages/Login';
import Error from './components/pages/Error';
import NotFound from './components/pages/NotFound';

// On route changes
const usePageChange = () => {
	// Hooks
	const location = useLocation();
	const { setMessage } = useMessage();

	// Initialize GA
	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
	}, []);

	// Clear message bar
	useEffect(() => {
		setMessage(null);
	}, [location, setMessage]);
};

const App = () => {
	// Hooks
	const { message, setMessage } = useMessage();
	usePageChange();

	// Effects
	useEffect(() => {
		if (message) {
			const { persist } = { ...message };
			const timeout = !persist ? setTimeout(() => setMessage(null), 5000) : null;
			return () => clearTimeout(timeout);
		}
	}, [message, setMessage]);

	// Render app
	return (
		<SWRConfig value={{ revalidateOnFocus: false, shouldRetryOnError: false, fetcher: url => fetcher(url) }}>
			<Helmet>
				<title>AppFolio Investment Management | Project Plan Tool</title>
			</Helmet>
			<Routes>
				<Route exact path='error' element={<Error />} />
				<Route exact path='login' element={<Login />} />
				<Route exact path='view/:id' element={<View />} />
				<Route exact path='brief/:id/content' element={<Content />} />
				<Route path='brief/:id/view' element={<ViewBrief />} />
				<Route path='*' element={<ProtectedRoute />} />
				<Route path='*' element={<NotFound />} />
			</Routes>
		</SWRConfig>
	);
};

export default App;
