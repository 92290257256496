import { calculateDueDate, calculateStatus } from 'utils/Stages';
import { tidyDateWords } from 'utils/Tidy';

import styled from 'styled-components';

const Component = styled.h4`
	color: ${({ $status }) => `var(--bs-${$status})`};
`;

const DueDate = ({ plan }) => {
	// Destructure props
	const { stage, timeline, on_hold } = { ...plan };

	// Stage calculations
	const dueDate = calculateDueDate(stage, timeline);
	const status = calculateStatus(on_hold, stage, dueDate);

	// Status Map
	const statusMap = {
		'On Time': 'success',
		Delayed: 'warning',
		Overdue: 'danger'
	};

	return stage !== 'Live' && <Component $status={statusMap[status]}>{tidyDateWords(dueDate)}</Component>;
};

export default DueDate;
