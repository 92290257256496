import styled from 'styled-components';
import { useCSVReader } from 'react-papaparse';
import { Button } from 'react-bootstrap';
import { tidyFileSize } from 'utils/Tidy';
import { useMessage } from 'utils/MessageContext';
import { getChartColor } from 'utils/Colors';

const Component = styled.div`
	max-width: 720px;
	margin: 0 auto;
`;

const UploadZone = styled.div`
	border: 3px dashed #ddd;
	border-radius: 0.5rem;
	padding: 2rem;
	display: grid;
	place-items: center;
	min-height: 200px;
`;

const Icon = styled.i`
	font-size: 3rem;
`;

const Details = styled.div`
	display: flex;
	gap: 1rem;
`;

const ProgressStyle = {
	marginTop: '1.5rem',
	background: getChartColor('primary'),
	boxShadow: 'none'
};

const Import = ({ importedCsv, setImportedCsv }) => {
	// Hooks
	const { CSVReader } = useCSVReader();
	const { setMessage } = useMessage();

	// Handlers
	const handleFileUpload = data => {
		try {
			if (!data.every(item => item.name)) {
				throw new Error('Each row must contain the field "name".');
			}
			setImportedCsv(data);
		} catch ({ message }) {
			setMessage({ variant: 'danger', text: `There was an error uploading this file: ${message}` });
		}
	};

	return (
		<Component>
			<h5 className='mb-4'>Upload the completed csv file below</h5>
			<CSVReader onUploadAccepted={({ data }) => handleFileUpload(data)} config={{ header: true, transform: value => value.replaceAll('\n', '<br />') }}>
				{({ getRootProps, acceptedFile, getRemoveFileProps, ProgressBar }) => (
					<UploadZone>
						{!acceptedFile || importedCsv.length === 0 ? (
							<Button variant='dark' {...getRootProps()}>
								Browse file <i className='fas fa-upload'></i>
							</Button>
						) : (
							<div>
								<Details>
									<Icon className='fas fa-file-csv text-muted' />
									<div>
										<strong>{acceptedFile.name}</strong>
										<div>{tidyFileSize(acceptedFile.size)}</div>
									</div>
								</Details>
								<Button variant='danger' {...getRemoveFileProps()} className='d-block mt-4 mx-auto'>
									Remove file <i className='fas fa-trash-alt'></i>
								</Button>
								<ProgressBar style={ProgressStyle} />
							</div>
						)}
					</UploadZone>
				)}
			</CSVReader>
		</Component>
	);
};

export default Import;
