import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import useSWR from 'swr';
import MainWrapper from 'components/shared/MainWrapper';
import styled from 'styled-components';
import { calculateDueDate } from 'utils/Stages';

import { tidyDateWords, tidyUrl, tidyLocalDateTime } from 'utils/Tidy';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import PlanStatus from 'components/shared/PlanStatus';
import Timeline from './Timeline';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';

const PanelWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 1rem;
	margin-top: 1rem;
`;

const Heading = styled.div``;

const Panel = styled.div`
	background: white;
	padding: 1rem;
	border-radius: 0.5rem;
	box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
	overflow: auto;
`;

const Name = styled.p`
	margin: 0;
	font-weight: bold;
	font-size: 1rem;
`;

const Value = styled.div`
	font-size: 1.25rem;
	margin: 0;
	color: var(--bs-secondary) !important;
`;

const Event = styled.div`
	line-height: 1.25;
	margin: 0.25rem 0;

	p {
		margin: 0;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

const Plan = () => {
	// Component state
	const [plan, setPlan] = useState(null);
	const [mounted, setMounted] = useState(false);

	// Hooks
	const { id } = useParams();
	const { data } = useSWR(mounted ? `/api/v1/plans/${id}` : null, `/api/v1/plans/${id}`);

	// Effects
	useEffect(() => {
		setMounted(true);
	}, []);

	useEffect(() => {
		data && setPlan(data);
	}, [data]);

	// Destructure state
	const { assets_url, kick_off_url, revisions_url, client_access_url, company_name, company_contact, on_hold, stage, timeline, live_website_url, duda_site_id, events } = { ...plan };
	const dueDate = plan && calculateDueDate(stage, timeline);

	// Events
	const nextEvent = events && events.length > 0 && events.find(event => dayjs(event.date).isAfter(dayjs()));

	return (
		<>
			<Helmet>
				<title>{company_name || ''} | AppFolio Investment Management | Project Plan Tool</title>
			</Helmet>
			<Row className='flex-lg-nowrap justify-content-between mb-5'>
				<Col lg={9}>
					<Heading>
						<h1>{company_name}</h1>
						<p className='text-muted m-1 text-truncate'>
							{company_contact ? (
								<>
									<i className='fas fa-check-circle text-success me-1'></i>
								</>
							) : (
								<i className='fas fa-times-circle text-danger me-1'></i>
							)}{' '}
							Company Contact: <a href={`mailto:${company_contact}`}>{company_contact}</a>
						</p>
						<p className='text-muted m-1 text-truncate'>
							{duda_site_id ? (
								<>
									<i className='fas fa-check-circle text-success me-1'></i>
								</>
							) : (
								<i className='fas fa-times-circle text-danger me-1'></i>
							)}{' '}
							Duda Site ID: <span className='text-dark'>{duda_site_id}</span>
						</p>
						<p className='text-muted m-1 text-truncate'>
							{live_website_url ? (
								<>
									<i className='fas fa-check-circle text-success me-1'></i>
								</>
							) : (
								<i className='fas fa-times-circle text-danger me-1'></i>
							)}{' '}
							Live Site URL:{' '}
							<a href={live_website_url} target='_blank' rel='noopener noreferrer'>
								{tidyUrl(live_website_url)}
							</a>
						</p>

						<p className='text-muted m-1 text-truncate'>
							{assets_url ? (
								<>
									<i className='fas fa-check-circle text-success me-1'></i>
								</>
							) : (
								<i className='fas fa-times-circle text-danger me-1'></i>
							)}{' '}
							Dropbox URL:{' '}
							<a href={assets_url} target='_blank' rel='noopener noreferrer'>
								{tidyUrl(assets_url)}
							</a>
						</p>
						<p className='text-muted m-1 text-truncate'>
							{kick_off_url ? (
								<>
									<i className='fas fa-check-circle text-success me-1'></i>
								</>
							) : (
								<i className='fas fa-times-circle text-danger me-1'></i>
							)}{' '}
							Kick-Off Call URL:{' '}
							<a href={kick_off_url} target='_blank' rel='noopener noreferrer'>
								{tidyUrl(kick_off_url)}
							</a>
						</p>
						<p className='text-muted m-1 text-truncate'>
							{revisions_url ? (
								<>
									<i className='fas fa-check-circle text-success me-1'></i>
								</>
							) : (
								<i className='fas fa-times-circle text-danger me-1'></i>
							)}{' '}
							Revisions Call URL:{' '}
							<a href={revisions_url} target='_blank' rel='noopener noreferrer'>
								{tidyUrl(revisions_url)}
							</a>
						</p>
						<p className='text-muted m-1 text-truncate'>
							{client_access_url ? (
								<>
									<i className='fas fa-check-circle text-success me-1'></i>
								</>
							) : (
								<i className='fas fa-times-circle text-danger me-1'></i>
							)}{' '}
							Client Access URL:{' '}
							<a href={client_access_url} target='_blank' rel='noopener noreferrer'>
								{tidyUrl(client_access_url)}
							</a>
						</p>
					</Heading>
				</Col>
				<Col lg={3} className='mt-3'>
					<ButtonContainer>
						<Button as={Link} variant='primary' to={`/edit/${id}`}>
							Edit Plan <i className='fas fa-edit'></i>
						</Button>
						<Button variant='secondary' icon='fa-link' text='Customer Link' href={`/view/${id}`} target='_blank' rel='noopener noreferrer'>
							Customer Link <i className='fas fa-link'></i>
						</Button>
						<Button as={Link} variant='dark' to={`/brief/${id}`}>
							Creative Brief <i className='fas fa-file-alt'></i>
						</Button>
					</ButtonContainer>
				</Col>
			</Row>
			{plan ? (
				<MainWrapper>
					<PanelWrapper>
						<Panel>
							<Name>Project Status:</Name>
							<Value>
								<PlanStatus on_hold={on_hold} stage={stage} dueDate={dueDate} showDelay />
							</Value>
						</Panel>
						<Panel>
							<Name>Current Stage:</Name>
							<Value>{stage}</Value>
						</Panel>
						<Panel>
							<Name>Target Go-Live Date:</Name>
							<Value>{tidyDateWords(timeline.find(stage => stage.name === 'Live').date)}</Value>
						</Panel>
						{nextEvent && (
							<Panel>
								<Name>Upcoming Event:</Name>
								<Event>
									<p>{nextEvent.name}</p>
									<small className='text-muted'>{tidyLocalDateTime(nextEvent.date)}</small>
								</Event>
							</Panel>
						)}
					</PanelWrapper>
					{!on_hold ? (
						<Timeline plan={plan} />
					) : (
						<div className='mt-5'>
							<h4 className='mb-3'>
								<i className='fas fa-exclamation-circle text-danger me-1'></i> This project has been put on hold.
							</h4>
							<p>
								The customer link instructs them to contact <a href={`mailto:${plan.appfolio_onboarding_contact}`}>{plan.appfolio_onboarding_contact}</a> to continue the development of their website.
							</p>
						</div>
					)}
				</MainWrapper>
			) : (
				<LoadingOverlay />
			)}
		</>
	);
};

export default Plan;
