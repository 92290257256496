import styled from 'styled-components';
import { calculateDueDate, calculateStatus } from 'utils/Stages';
import { tidyDateWords } from 'utils/Tidy';

const Component = styled.div`
	margin: 3rem auto 5rem;

	@media (max-width: 767px) {
		margin: 1rem auto;
		display: flex;
		justify-content: center;
		align-items: space-around;
		gap: 1rem;
	}
`;

const Text = styled.div`
	text-align: left;
`;

const Row = styled.div`
	display: flex;
	justify-content: space-around;

	@media (max-width: 767px) {
		flex-direction: column;
		margin: 0 auto;
	}
`;

const IconHolder = styled.div`
	position: relative;
	height: 6rem;
`;

const Icon = styled.div`
	height: 3rem;
	width: 3rem;
	border-radius: 50%;
	margin: 1rem 0;
	background: var(--bs-light);
	display: grid;
	place-items: center;
	font-size: 1.5rem;
	color: white;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	z-index: 1;
`;

const Link = styled.div`
	position: absolute;
	top: calc(50% + 1rem);
	transform: translateY(-50%);
	width: 100%;
	height: 0.25rem;
`;

const Name = styled.h5`
	margin: 0 0 0.25rem;
	font-weight: normal;
`;

const Stage = styled.div`
	flex: 1 0 8rem;

	&:last-of-type {
		flex: 0;
	}

	&:not(:last-of-type) {
		${Link} {
			background: ${({ $completed, $active, $status }) => ($completed && !$active ? `var(--bs-${$status})` : 'var(--bs-light)')};
		}
	}

	${Name} {
		font-weight: ${({ $active }) => $active && 'bold'};
	}

	${Icon} {
		width: ${({ $active }) => $active && '5rem'};
		height: ${({ $active }) => $active && '5rem'};
		font-size: ${({ $active }) => $active && '2.5rem'};
		background: ${({ $completed, $active, $status }) => ($active || $completed) && `var(--bs-${$status})`};
	}

	@media (max-width: 767px) {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;

		${Text} {
			flex: 0 0 6rem;
		}

		${IconHolder} {
			flex: 0 0 6rem;
			display: flex;
			justify-content: center;
		}

		${Icon} {
			position: relative;
			margin: 0;
		}

		${Link} {
			width: 0.25rem;
			height: 100%;
			top: calc(100% + 1rem);
		}
	}
`;

const Date = styled.p`
	margin: 0;
	color: var(--bs-secondary);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const Timeline = ({ plan }) => {
	// Destructure proprs
	const { stage: currentStage, timeline, on_hold } = { ...plan };

	// Stage calculations
	const dueDate = calculateDueDate(currentStage, timeline);
	const status = calculateStatus(on_hold, currentStage, dueDate);

	const currentStageIndex = timeline.findIndex(stage => stage.name === currentStage);
	const completedStages = timeline.filter((_, index) => index <= currentStageIndex).map(stage => stage.name);

	const statusMap = {
		'On Time': 'success',
		Delayed: 'warning',
		Overdue: 'danger',
		Live: 'primary'
	};

	return (
		<Component>
			<Row>
				{timeline.map(stage => (
					<Stage key={stage.name} $status={statusMap[status]} $active={currentStage === stage.name ? true : false} $completed={completedStages.includes(stage.name)}>
						<Text>
							<Name>{stage.name}</Name>
							<Date>{tidyDateWords(stage.date)}</Date>
						</Text>
						<IconHolder>
							<Icon>
								{currentStage === stage.name && <i className='fas fa-angle-double-right'></i>}
								{completedStages.includes(stage.name) && currentStage !== stage.name && <i className='fas fa-check'></i>}
							</Icon>
							<Link />
						</IconHolder>
					</Stage>
				))}
			</Row>
		</Component>
	);
};

export default Timeline;
