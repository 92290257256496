import styled from 'styled-components';

import { Helmet } from 'react-helmet-async';

const Component = styled.div`
	color: var(--bs-danger);
	text-align: center;
	padding: 3rem 1rem;
`;

const Heading = styled.h1`
	font-size: 5rem;
`;

const Text = styled.p`
	margin: 0;
`;

const NotFound = () => {
	return (
		<Component>
			<Helmet>
				<title>404 | AppFolio Investment Management | Project Plan Tool</title>
			</Helmet>
			<Heading>
				<i className='fas fa-exclamation-circle'></i> 404
			</Heading>
			<Text>Sorry, looks like we couldn't find that page.</Text>
		</Component>
	);
};

export default NotFound;
