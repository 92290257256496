import { useState, useEffect } from 'react';
import logo from 'img/app/appfolio_logo.svg';
import styled from 'styled-components';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { tidyLocalDateTime } from 'utils/Tidy';
import LoadingOverlay from 'components/shared/LoadingOverlay';
import { getJwt } from 'utils/Auth';

// Sections
import ProjectDetails from './Sections/ProjectDetails';
import CompanyInfo from './Sections/CompanyInfo';
import WebsiteContent from './Sections/WebsiteContent';

const Component = styled.div`
	padding: 3rem;
	max-width: 1280px;
	width: 100%;
	margin: 0 auto;

	@media (max-width: 767px) {
		padding: 1rem;
	}
`;

const Header = styled.div`
	display: flex;
	gap: 1rem;
	align-items: center;
	margin-bottom: 3rem;
`;

const Content = () => {
	// State
	const [mounted, setMounted] = useState(false);

	// Hooks
	const { id } = useParams();
	const { data } = useSWR(mounted ? `/api/v1/briefs/${id}` : null, `/api/v1/briefs/${id}`);

	// Effects
	useEffect(() => {
		const init = async () => {
			await getJwt();
			setMounted(true);
		};
		init();
	}, []);

	// Destructure data
	const { creative_brief } = { ...data };
	const { company_name, last_saved } = { ...creative_brief };

	return data ? (
		<Component>
			<Header>
				<img src={logo} alt='AppFolio Investment Management Logo' width='75' />
				<div>
					<h1 className='mb-2'>Writing Content Brief - {company_name}</h1>
					<p className='text-muted m-0'>Last updated {tidyLocalDateTime(last_saved)}</p>
				</div>
			</Header>
			<ProjectDetails creative_brief={creative_brief} />
			<CompanyInfo creative_brief={creative_brief} />
			<WebsiteContent creative_brief={creative_brief} />
		</Component>
	) : (
		<LoadingOverlay />
	);
};

export default Content;
