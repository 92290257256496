import { Row, Col, Form, Button } from 'react-bootstrap';
import { tidyDatePicker } from 'utils/Tidy';
import { useParams, Link } from 'react-router-dom';

const Timeline = ({ timeline }) => {
	// Hooks
	const { id } = useParams();

	return (
		<div className='my-3'>
			<Row>
				<Col lg={3}>
					<div className='mb-3'>
						<Form.Label>Collect Date</Form.Label>
						<Form.Control type='date' value={tidyDatePicker(timeline?.find(stage => stage.name === 'Collect').date) || '0'} disabled></Form.Control>
					</div>
				</Col>
				<Col lg={3}>
					<div className='mb-3'>
						<Form.Label>Build Date</Form.Label>
						<Form.Control type='date' value={tidyDatePicker(timeline?.find(stage => stage.name === 'Build').date) || '0'} disabled></Form.Control>
					</div>
				</Col>
				<Col lg={3}>
					<div className='mb-3'>
						<Form.Label>Review Date</Form.Label>
						<Form.Control type='date' value={tidyDatePicker(timeline?.find(stage => stage.name === 'Review').date) || '0'} disabled></Form.Control>
					</div>
				</Col>
				<Col lg={3}>
					<div className='mb-3'>
						<Form.Label>Live Date</Form.Label>
						<Form.Control type='date' value={tidyDatePicker(timeline?.find(stage => stage.name === 'Live').date) || '0'} disabled></Form.Control>
					</div>
				</Col>
			</Row>
			<Button as={Link} to={`/edit/${id}`} className='mt-3'>
				Edit Dates in Project Plan <i className='far fa-calendar-alt'></i>
			</Button>
		</div>
	);
};

export default Timeline;
