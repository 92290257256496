import { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const Component = styled.div``;

const HeaderRow = styled.div`
	display: grid;
	grid-template-columns: 15rem 1fr;
	align-items: start;
	margin-bottom: 1rem;
	gap: 1rem;
`;

const Name = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Details = ({ importedCsv, setCollectionData }) => {
	// Props
	const [collectionName, setCollectionName] = useState('');
	const [siteId, setSiteId] = useState('');

	// State
	const [typeMap, setTypeMap] = useState([
		{ name: 'name', type: 'plain_text' },
		{ name: 'address', type: 'plain_text' },
		{ name: 'general_info', type: 'text' }
	]);

	// Handlers
	const handleChange = e => {
		const { name, value } = e.target;
		const newMap = [...typeMap];
		const current = newMap.findIndex(item => item.name === name);
		newMap[current] = { name, type: value };
		setTypeMap(newMap);
	};

	// Effects
	useEffect(() => {
		const headers = Object.keys(importedCsv[0]);
		const fieldNames = typeMap.map(field => field.name);
		const defaultFields = typeMap.filter(field => headers.includes(field.name));
		const newFields = headers.filter(header => !fieldNames.includes(header)).map(field => ({ name: field, type: 'plain_text' }));
		setTypeMap([...defaultFields, ...newFields]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setCollectionData(prev => ({ ...prev, name: collectionName, site_id: siteId, fields: typeMap }));
	}, [collectionName, siteId, typeMap, setCollectionData]);

	return (
		<Component>
			<h5>Instructions</h5>
			<ol className='mb-5'>
				<li>Select the appropriate field type for each field in this collection. Known fields have been set with a default type.</li>
				<li>Specify a name for this collection. It is recommended not to use any special characters or spaces in the name.</li>
				<li>Enter the site ID where this collection will be created and uploaded to.</li>
				<li>Hit submit to create this collection using all verified data within the assigned site ID.</li>
			</ol>
			<Row>
				<Col lg={6}>
					<h5 className='mb-3'>1. Field Types</h5>
					<HeaderRow>
						<div>
							<strong>Field name</strong>
						</div>
						<div>
							<strong>Field type</strong>
							<small className='d-block text-muted'>Leave this set to 'Plain Text' if the field data does not require line breaks or formatting.</small>
						</div>
					</HeaderRow>
					{typeMap.map((field, i) => (
						<HeaderRow key={field.name + i}>
							<Name>{field.name}</Name>
							<Form.Select className='shadow-sm rounded' name={field.name} onChange={e => handleChange(e)} value={field.type} disabled={field.name === 'name'}>
								<option value='plain_text'>Plain Text</option>
								<option value='text'>Rich Text</option>
								<option value='phone'>Phone</option>
								<option value='email'>Email</option>
								<option value='link'>Link</option>
								<option value='video'>Video</option>
							</Form.Select>
						</HeaderRow>
					))}
				</Col>
				<Col lg={6}>
					<h5 className='mb-3'>2. Collection Name</h5>
					<Form.Group>
						<Form.Label>Collection name</Form.Label>
						<Form.Control type='text' className='shadow-sm rounded' value={collectionName} onChange={e => setCollectionName(e.target.value)} required />
						<Form.Text className='text-muted'>It is recommended not to use any special characters or spaces.</Form.Text>
					</Form.Group>
					<h5 className='mt-5 mb-3'>3. Site ID</h5>
					<Form.Group>
						<Form.Label>Site ID</Form.Label>
						<Form.Control type='text' className='mr-2 border-0 shadow-sm' value={siteId} minLength={8} maxLength={8} pattern='[a-zA-Z0-9]{8}$' onChange={e => setSiteId(e.target.value)} required />
						<Form.Text className='text-muted'>8 character site ID.</Form.Text>
					</Form.Group>
				</Col>
			</Row>
		</Component>
	);
};

export default Details;
